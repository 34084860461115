<!--
 * @Author: 刘格优
 * @Date: 2020-02-29 19:04:55
 * @LastEditors: 刘格优
 * @LastEditTime: 2020-04-24 10:29:07
 -->

<template>
  <div class="listbox">
    <!-- <div class="contentbox">
      <div class="background">

      </div>
      <div class="neirong">
        <div @click="toshow('jl')">
          <img src="@/assets/nh/jlq.png"
               alt="">
          <span :class="tabtype == '0' ? 'orange':''">奖励券<span class="bigword"
                  :class="tabtype == '0' ? 'orange':''">{{yhqnum}}</span></span>
        </div>
        <div @click="toshow('jf')">
          <img src="@/assets/nh/jf.png"
               alt="">
          <span :class="tabtype == '1' ? 'orange':''">积分<span class="bigword"
                  :class="tabtype == '1' ? 'orange':''">{{jfnum}}</span></span>
        </div>
      </div>
    </div> -->
    <div class="bottom">
      <div class="nodata"
           v-show="isNull">
        <img class="kongimg"
             src="@/assets/image/null.png"
             alt="" />
      </div>
      <ul v-show="!isNull && tabtype == '0'">
        <li v-for="(item,index) in yhqlists"
            :key="index"
            class="lis">
          <div class="yhqlist"
               :class="item.type == '1'?'xjl':'fwl'"
               @click="todetail(item)">
            <p class="one"
               v-if="item.type == '1'">￥<span>{{item.amount}}</span></p>
            <p class="two"
               v-if="item.type == '1'">活动奖励</p>
            <p class="three">{{item.couponname}}</p>
            <p class="four"
               v-if="item.endtime">有效期至：{{item.endtime}}</p>
            <div class="five">发放时间：{{item.grantdate}}</div>
            <img src="@/assets/nh/to.png"
                 alt=""
                 class="seven">
            <img src="@/assets/nh/yff.png"
                 alt=""
                 class="eight"
                 v-if="item.empCouponStatus == '0'">
            <img src="@/assets/nh/ksy.png"
                 alt=""
                 class="eight"
                 v-if="item.empCouponStatus == '1'">
            <img src="@/assets/nh/ysq.png"
                 alt=""
                 class="eight"
                 v-if="item.empCouponStatus == '2'">
            <img src="@/assets/nh/ysy.png"
                 alt=""
                 class="eight"
                 v-if="item.empCouponStatus == '3'">
            <img src="@/assets/nh/ysx.png"
                 alt=""
                 class="eight"
                 v-if="item.empCouponStatus == '4'">
            <img src="@/assets/nh/yzf.png"
                 alt=""
                 class="eight"
                 v-if="item.empCouponStatus == '5'">
          </div>
          <div class="six"
               v-if="item.type == '2' && item.empCouponStatus == '1'"
               @click="touse(item)">
            立即使用
          </div>
        </li>
      </ul>
      <div class="content"
           v-show="!isNull && tabtype == '1'">
        <md-scroll-view class="scroll"
                        ref="scrollView"
                        auto-reflow
                        :scrolling-x="false"
                        @end-reached="loadMorechange">
          <div class="line"
               v-for="(item,index) in lists"
               :key="index">
            <div class="l_date">
              <div class="l_label">{{ item.remark}}</div>
              <p>{{item.jftime}}</p>
            </div>
            <div class="l_value">
              <span>+{{item.jf}}</span>
            </div>
          </div>
          <md-scroll-view-more slot="more"
                               :is-finished="listFinished" />
        </md-scroll-view>
      </div>
    </div>
  </div>
</template>

<script>
import { selectempCouponList, useFWCoupon, getJFAndYhqTotal, jfInfo } from '@/api/basic'
import { getStorage } from '@/lib/util'
import { Dialog } from 'mand-mobile'
import loadMorechange from '@/mixins/loadMorechange'
export default {
  mixins: [loadMorechange],
  components: {
    [Dialog.name]: Dialog,
  },
  data () {
    return {
      // type:1未使用券，2无效券
      type: '1',
      yhqlists: [],
      ossurl: '',
      isNull: true,
      yhqnum: '',
      jfnum: '',
      tabtype: '0',
      listpageNo: 1,
      listsize: 9,
      listtotal: 0,
      listtotalPage: 0,
      loadflag: false,
      listFinished: false,
      lists: [],
    }
  },

  created () {
    this.ossurl = getStorage('ossurl', '')
    this.getjldata()
    this.getnum()
  },

  methods: {
    getnum () {
      getJFAndYhqTotal().then((res) => {
        this.yhqnum = res.data.data.yhnum
        this.jfnum = res.data.data.jfnum
      })
    },
    getjldata () {
      selectempCouponList({ type: this.type }).then((res) => {
        this.yhqlists = res.data.data
        if (this.yhqlists.length == 0) {
          this.isNull = true
        } else {
          this.isNull = false
        }
      })
    },
    todetail (item) {
      if (item.empCouponStatus != '5') {
        this.$router.push({ path: `/yhqdetail?showhttp=${item.showhttp}` })
      }
    },
    touse (item) {
      Dialog.confirm({
        title: '申请使用',
        content: '请确认是否立即使用',
        confirmText: '确定',
        onConfirm: () => {
          useFWCoupon({ couponcode: item.couponcode }).then((res) => {
            this.getjldata()
          })
        },
      })
    },
    toshow (type) {
      if (type == 'jl') {
        this.tabtype = '0'
        this.yhqlists = []
        this.getjldata()
      } else {


        this.listpageNo = 1
        this.lists = []
        this.listFinished = false
        this.loadflag = false
        this.$refs.scrollView.finishLoadMore()
        this.tabtype = '1'
        this.getData()
      }
    },
    getData () {
      let data = {
        page: this.listpageNo,
        size: this.listsize,
      }
      jfInfo(data).then(res => {
        this.lists =
          this.lists == []
            ? res.data.data.rows
            : this.lists.concat(res.data.data.rows)
        if (this.lists.length > 0) {
          this.isNull = false
        } else {
          this.isNull = true
        }
        this.listtotal = res.data.data.total
        this.listtotalPage = res.data.data.totalpage

        if (this.listtotalPage <= this.listpageNo) {
          this.listFinished = true
        } else {
          this.listFinished = false
          this.$refs.scrollView.finishLoadMore()
        }
        this.loadflag = true
      })
    },
  }
}

</script>
<style scoped lang="stylus">
.listbox {
  height: 100%;

  .contentbox {
    width: 100%;
    height: 20%;

    // 
    .background {
      background: #f5edeb;
      height: 75%;
    }

    .neirong {
      width: 90%;
      height: 2.5rem;
      background-color: #ffffff;
      box-shadow: 0.01rem 0.01rem 0.5rem 0.1rem rgba(0, 0, 0, 0.05);
      margin-left: 5%;
      position: relative;
      bottom: 47%;
      display: flex;
      align-items: center;
      justify-content: center;

      .bigword {
        font-size: 0.4rem;
        font-family: PingFang SC;
        font-weight: bold;
        padding-left: 0.1rem;
      }

      div {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 30%;
          height: auto;
          padding-right: 4%;
        }

        span {
          color: #383838;
          font-size: 0.36rem;
        }
      }

      .orange {
        color: #FB6821;
      }
    }
  }

  .bottom {
    // height: 75%;
    // margin-top: 5%;
    overflow-y: auto;
    height: 100%;
    padding: 5% 0;

    .content {
      height: 100%;
    }
  }

  .lis {
    margin-bottom: 0.3rem;
    text-align: center;
    height: 2.6rem;
    padding: 0 4%;
    position: relative;

    .yhqlist {
      box-shadow: 0.01rem 0.01rem 0.5rem 0.001rem rgba(0, 0, 0, 0.05);

      .one {
        position: absolute;
        top: 18%;
        color: #FFFFFF;
        left: 6vw;
        font-size: 0.45rem;

        span {
          font-size: 0.8rem;
        }
      }

      .two {
        position: absolute;
        top: 60%;
        color: #fff;
        left: 7.5vw;
        font-size: 0.35rem;
      }

      .three {
        position: absolute;
        top: 14%;
        color: #383838;
        left: 34vw;
        font-size: 0.4rem;
      }

      .four {
        position: absolute;
        top: 45%;
        left: 34vw;
        font-size: 0.3rem;
        color: #9B9B9B;
      }

      .five {
        position: absolute;
        bottom: 10%;
        left: 34vw;
        font-size: 0.3rem;
        color: #9B9B9B;
      }

      .seven {
        position: absolute;
        width: 0.5rem;
        height: 0.5rem;
        right: 3vw;
        bottom: 8%;
      }

      .eight {
        width: 1.3rem;
        height: auto;
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    .six {
      position: absolute;
      right: 6vw;
      top: 36%;
      font-size: 0.3rem;
      border: 1px solid #FA6E79;
      border-radius: 0.3rem;
      color: #FA6E79;
      padding: 0.1rem 0.2rem;
      z-index: 99;
    }

    .xjl {
      background-image: url('~@/assets/nh/xjl.png');
      background-size: 100% auto;
      background-repeat: no-repeat;
      height: 100%;
      position: relative;
    }

    .fwl {
      background-image: url('~@/assets/nh/fwl.png');
      background-size: 100% auto;
      background-repeat: no-repeat;
      height: 100%;
      position: relative;
    }
  }
}

.nodata {
  padding-top: 30%;
  text-align: center;

  .kongimg {
    width: 300px;
    height: auto;
  }
}

.line {
  background-color: #FFFFFF;
  align-items: center;
  height: 200px;
  font-family: PingFang SC;
  width: 93.35%;
  border-bottom: 1px solid #EAEAEA;
  margin: 0px auto;
  display: flex;
  justify-content: space-between;
  text-align: left;

  .l_date {
    width: 50%;

    .l_label {
      padding: 5px 0 5px 0.6rem;
      font-size: 0.4rem;
    }

    p {
      padding: 5px 0 5px 0.6rem;
      width: 100%;
      height: 5%;
      font-size: 0.28rem;
      color: #A3A3A3;
    }
  }

  .l_value {
    width: 15.35%;

    span {
      width: 30%;
      height: 5%;
      font-size: 0.45rem;
      font-family: PingFang SC;
      color: rgba(44, 44, 60, 1);
    }
  }
}
</style>
